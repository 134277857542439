$backgroundColor: #333333;
$textColor: #ffffff;
$labelColor: #9b9b9b;
$inputColor: #4a4a4a;
$fadeTime: 0.25s;

@mixin inputValuePresent {
  font-size: 0.9rem;
  top: -1.5rem;
}

* {
  box-sizing: border-box;
  font-weight: lighter;
}

body {
  background-color: $backgroundColor;
  color: $textColor;
  font-family: "Open Sans", Helvetica;
  margin: 0 auto;
  padding: 2rem;
}

.headline {
  color: $textColor;
  font-size: 1.75rem;
  margin: 0 0 4rem 0;
  padding: 0;
  text-align: center;
}

form {
  text-align: center;
}

.field {
  position: relative;

  label {
    color: $labelColor;
    display: block;
    font-size: 1.5rem;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s;
    width: 100%;
    z-index: -1;

    &.present {
      @include inputValuePresent();
    }
  }

  input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid $inputColor;
    color: $textColor;
    font-size: 1.25rem;
    margin: 0 0 3rem 0;
    outline: 0;
    padding: 0.25rem 0;
    text-align: center;
    transition: all 0.3s;
    width: 320px;

    &:focus {
      width: 450px;
      max-width: 100%;
    }

    &:focus,
    &:valid {
      & + label {
        @include inputValuePresent();
      }
    }
  }
}

.actions {
  margin-top: 2rem;

  button {
    background-color: $backgroundColor;
    border-radius: 27px;
    border: 2px solid $textColor;
    color: $textColor;
    cursor: pointer;
    font-size: 1.5rem;
    outline: 0;
    padding: 0.5rem 1rem;
    transition: all 200ms;

    &:hover,
    &:focus {
      background-color: $textColor;
      color: $backgroundColor;
    }
  }
}

.flash-messages {
  left: 0;
  margin: 0;
  padding: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all $fadeTime;

  &-leave {
    transform: translate(0, -200%);
  }

  .flash-message {
    font-size: 1rem;
    margin: 0;
    padding: 1rem;
    text-align: center;
    width: 100%;

    &--notice {
      background-color: greenyellow;
      color: green;
    }
    &--alert {
      background-color: red;
      color: #fff;
    }
  }
}
